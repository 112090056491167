const cacheStore = new Map();

const generateKey = (args) => {
	const argsKey = JSON.stringify(args);
	// return `${path}::${argsKey}`;
	return argsKey
};

export const getCache = (args) => {
	const key = generateKey(args)
	if(!cacheStore.has(key))
		throw new Error(`Cache not found for cache-key: ${key}`)
	return cacheStore.get(key)
}

export const setCache = (args, payload) => {
	const key = generateKey(args)
	cacheStore.set(key, payload)
}

export const clearCache = (args) => {
	// if(args == '*')
	// 	Array.from(cacheStore.keys())
	// 		.filter(key => key.startsWith(`${path}::`))
	// 		.forEach(key => cacheStore.delete(key));
	// else {
		const key = generateKey(args);
		cacheStore.delete(key);
	// }
}

export const cachedFn = async (fn, args) => {
	const key = generateKey(args)

	console.info('CACHE', cacheStore.has(key) ? 'hit' : 'miss')
	if(cacheStore.has(key))
		return cacheStore.get(key)
	const result = await fn(args)
	setCache(args, result)
	return result
}
